import { LexoRank } from 'lexorank'

export class LexoRankService {
  static calculateRank(rankAbove: LexoRank | null = null, rankBelow: LexoRank | null = null): LexoRank {
    if (rankAbove === null) {
      if (rankBelow === null) {
        return LexoRank.middle()
      } else {
        return rankBelow.genPrev()
      }
    } else {
      if (rankBelow === null) {
        return rankAbove.genNext()
      } else {
        return rankAbove.between(rankBelow)
      }
    }
  }
}
