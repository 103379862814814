




import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'

@Component
export default class Estimation extends Vue {
  @Prop() private issue!: Issue
}
