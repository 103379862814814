
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'

@Component
export default class TaskList extends Vue {
  @Prop()
  private task!: Issue

  private showDescription = false
}
