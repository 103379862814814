



import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'
import { TranslationHelper } from '@/services/translation/TranslationHelper'

@Component
export default class IssueStatus extends Vue {
  @Prop()
  private issue!: Issue

  get translateStatus() {
    return this.$i18n.t(TranslationHelper.translateStatus(this.issue.currentStatus))
  }
}
