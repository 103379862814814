













import { Component, Prop, Inject, Vue } from 'vue-property-decorator'

@Component
export default class SkeletonListLoader extends Vue {
  @Inject() theme: any

  @Prop({ default: 3 })
  private repeat!: number

  @Prop({ default: 'article' })
  private type!: string
}
