





















































































import draggable from 'vuedraggable'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Issue } from '@/models/Issue.model'
import IssueStatus from '@/main/issues/components/IssueStatus.vue'
import { IssueService } from '@/main/issues/services/IssueService'
import SkeletonListLoader from '@/components/shared/skeleton/ListLoader.vue'
import { LexoRankService } from '@/services/lexorank/LexoRankService'
import { WorkflowService } from '@/services/workflow/WorkflowService'
import { LexoRank } from 'lexorank'
import Task from '@/main/issues/components/task/Task.vue'

@Component({
  components: { Task, IssueStatus, draggable, SkeletonListLoader }
})
export default class TaskListDraggable extends Vue {
  @Prop()
  private issue!: Issue
  private newTaskText = ''
  private showNewTaskInput = false
  private taskIsSaving = false
  private tasksTodo: Issue[] = []
  private tasksDoing: Issue[] = []
  private tasksDone: Issue[] = []
  private currentlyDraggedIssue: Issue | null = null

  created() {
    this.tasksTodo = this.issue.tasksTodo()
    this.tasksDoing = this.issue.tasksDoing()
    this.tasksDone = this.issue.tasksDone()
  }

  newTask() {
    this.showNewTaskInput = true
  }

  setCurrentlyDraggedIssue(taskList: Issue[], event: any) {
    this.currentlyDraggedIssue = taskList[event.oldIndex]
  }

  clearCurrentlyDraggedIssue() {
    this.currentlyDraggedIssue = null
  }

  get dragOptions() {
    if (this.$vuetify.breakpoint.mobile) {
      return {
        animation: 200,
        handle: '.handle'
      }
    }
    return {
      animation: 200
    }
  }

  async saveNewTask() {
    this.taskIsSaving = true
    const title = this.newTaskText
    this.newTaskText = ''

    const previousTask = this.tasksTodo[this.tasksTodo.length - 1]

    const createdTask = await IssueService.createTask(this.issue, {
      rank: previousTask
        ? previousTask
            .lexoRank()
            ?.genNext()
            .toString()
        : LexoRank.middle().toString(),
      title: title
    })

    this.tasksTodo.push(createdTask)
    this.taskIsSaving = false
  }

  canApplyTransitions(event: any) {
    if (this.currentlyDraggedIssue === null) {
      return false
    }
    const isSameList = event.from.getAttribute('data-status') === event.to.getAttribute('data-status')

    return (
      isSameList ||
      this.currentlyDraggedIssue.enabledTransitions.filter(transition => {
        return transition.tos.includes(event.to.getAttribute('data-status'))
      }).length > 0
    )
  }

  updateLexoRank(taskList: Issue[], event: any) {
    const newIndex = event.newIndex
    let rankAbove = null
    let rankBelow = null

    if (taskList.length > 1) {
      if (newIndex === 0) {
        rankBelow = taskList[newIndex + 1].lexoRank()
      } else {
        rankAbove = taskList[newIndex - 1].lexoRank()
        if (newIndex < taskList.length - 1) {
          rankBelow = taskList[newIndex + 1].lexoRank()
        }
      }
    }

    taskList[newIndex].rank = LexoRankService.calculateRank(rankAbove, rankBelow).toString()
    IssueService.updateRank([taskList[newIndex]])
  }

  async enterList(taskList: Issue[], event: any) {
    const newIndex = event.newIndex
    const transitionName = event.to.getAttribute('data-transition')

    WorkflowService.applyTransition(taskList[newIndex].iri(), transitionName).then(response => {
      const issueFromResponse = Issue.createFromResponse(response)
      taskList[newIndex].currentStatus = issueFromResponse.currentStatus
      taskList[newIndex].enabledTransitions = issueFromResponse.enabledTransitions
    })

    this.updateLexoRank(taskList, event)
  }
}
